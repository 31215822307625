import { useState, useContext, useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { Alert } from '@pokemon/design-v2.alert'
import { Button } from '@pokemon/design-v2.button'
import { Form } from 'react-final-form'
import { LoadingContext, LoadingContextValue } from '@pokemon/design.ui.loading-spinner'
import { countryCodeReplacementString, localizeLink, getTwoCharacterCountryCodeFromLanguage, getSupportLink } from '@tpci/i18next-ext'
import { AuthContext, AuthContextValue } from '../../utils/auth/context'
import { retrieveProfile } from '../../utils/tamApi'
import './nintendo_link_page.css'

export interface NintendoLinkPageProps {
  setError: (text?: string) => void
}

enum NintendoSettings {
  NeedsLoad,
  LoadStarted,
  LinkDisabled,
  LinkEnabled,
}

export function NintendoLinkPage (props: NintendoLinkPageProps) {
  const { t, i18n } = useTranslation()
  const [nintendoSettings, setNintendoSettings] = useState(NintendoSettings.NeedsLoad)
  const { selectedAccount, isChildView, accessToken } = useContext<AuthContextValue>(AuthContext)
  const { setLoading } = useContext<LoadingContextValue>(LoadingContext)
  const { setError } = props

  function nintendoCountryCode () {
    const NINTENDO_LEGAL_DOCS_SUPPORTED_COUNTRIES = ['FR', 'IT', 'DE', 'ES', 'GB', 'US', 'DK', 'FI', 'NL', 'SE', 'BR', 'RU']
    const countryCode = getTwoCharacterCountryCodeFromLanguage(i18n.language)
    if (NINTENDO_LEGAL_DOCS_SUPPORTED_COUNTRIES.includes(countryCode)) {
      return countryCode
    } else if (i18n.language === 'es-xl') {
      return 'MX'
    } else {
      return 'US'
    }
  }

  const privacyLink = localizeLink(i18n.language, `https://www.pokemon.com/${countryCodeReplacementString}/privacy-notice/`)
  const nintendoTermsLink = (`https://accounts.nintendo.com/term/eula/${nintendoCountryCode()}`)
  const nintendoPrivacyLink = (`https://accounts.nintendo.com/term/privacy_policy/${nintendoCountryCode()}`)
  const supportLink = getSupportLink(i18n.language)

  const getNintendoLinkNum = useCallback(async () => {
    let childGuid
    if (isChildView) {
      childGuid = selectedAccount?.guid
    }
    try {
      const userData = await retrieveProfile(accessToken!.token, childGuid)
      return userData.data.nintendo_ppid
    } catch (error) {
      console.error(error)
      setError()
    }
  }, [accessToken, isChildView, selectedAccount?.guid, setError])

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    try {
      const isLinked = (await getNintendoLinkNum())
      if (!isLinked) {
        // this is for demo perposes, in future will redirect to nintendo
        setNintendoSettings(NintendoSettings.LinkEnabled)
      } else {
        // same as above, but for unlinking via nintendo
        setNintendoSettings(NintendoSettings.LinkDisabled)
      }
    } catch (error) {
      console.error(error)
      setError()
    } finally {
      setLoading(false)
    }
  }, [getNintendoLinkNum, setError, setLoading])

  const handlePromise = useCallback((promise: Promise<unknown>) => {
    setLoading(true)
    promise.catch(err => {
      console.error(err)
      setError()
    }).finally(() => {
      setLoading(false)
    })
  }, [setError, setLoading])

  const loadLinkStatus = useCallback(async () => {
    const isLinked = (await getNintendoLinkNum())
    if (!isLinked) {
      setNintendoSettings(NintendoSettings.LinkDisabled)
    } else {
      setNintendoSettings(NintendoSettings.LinkEnabled)
    }
  }, [getNintendoLinkNum])

  useEffect(() => {
    if (nintendoSettings === NintendoSettings.NeedsLoad) {
      setNintendoSettings(NintendoSettings.LoadStarted)
      handlePromise(loadLinkStatus())
    }
  }, [isChildView, nintendoSettings, handlePromise, loadLinkStatus])

  return (
    <>
      {nintendoSettings === NintendoSettings.LinkEnabled && (
        <BaseContentBox key="nintendo-link-enabled" className="nintendo-link-terms" customWidth={700} autoSizeBox={true} title={<div className = 'app-game-title'><img className='app-game-logo' src='https://static.pokemon.com/images/image 11.png' alt="" /><h1>{t('nintendo_link_header', 'Nintendo Link')}</h1></div>}>
          <Form
            onSubmit={handleSubmit}
            render={
              ({ handleSubmit, submitting }) => (
                <form className='nintendo-link-form' data-testid='nintendo_link_enabled_test' onSubmit={handleSubmit}>
                  <div>
                    <Alert severity="success" variant='outlined'>Congratulations! You are all set.</Alert>
                    <p>{t('nintendo_link_connected_one', 'Your Nintendo Account and your Pokémon Trainer Club account are now linked. You will now be able to earn Championship Points when competing in video game Online Competitions.')}</p>
                    <p>{t('nintendo_link_connected_two', 'To unlink your accounts, go to your Nintendo Account. If your accounts are unlinked, you will no longer be eligible to receive Championship Points from Online Video Game Competitions in which a Nintendo Account linked to a Pokémon Trainer Club account is required. Championship Points that are awarded to a Pokémon Trainer Club account’s Player ID will remain even if the Nintendo Account has been unlinked.')}</p>
                    <Button
                      text={t('nintendo_link_button', 'GO TO NINTENDO ACCOUNT')}
                      buttonStatus='primary'
                      data-testid="nintendo_link_button_test"
                      type="submit"
                      disabled={ submitting }
                      style={{ width: '100%' }}
                    />
                  </div>
                </form>
              )
            }
          />
        </BaseContentBox>
      )}
      {nintendoSettings === NintendoSettings.LinkDisabled && (
        <BaseContentBox key="nintendo-link-disabled" className="nintendo-link-terms" customWidth={700} autoSizeBox={true} title={<div className = 'app-game-title'><img className='app-game-logo' src='https://static.pokemon.com/images/image 11.png' alt="" /><h1>{t('nintendo_link_header', 'Nintendo Link')}</h1></div>}>
          <Form
            onSubmit={handleSubmit}
            render={
              ({ handleSubmit, submitting }) => (
                <form className='nintendo-link-form' data-testid='nintendo_link_disabled_test' onSubmit={handleSubmit}>
                  <div>
                    <p>{t('nintendo_link_information_one', 'In order for Championship Points earned playing Online Video Game Competitions on Nintendo Switch Online to be applied to your Player ID, you must first link your Nintendo Account and your Pokémon Trainer Club account. You’ll also need to have opted into the Play! Pokémon program and have a valid Player ID prior to the start of the competition in which the Championship Point rankings are calculated and until after Championship Points are awarded.')}</p>
                    <p>{t('nintendo_link_information_two', 'The button below will redirect you to the Nintendo site where you can log in to an existing Nintendo Account or create a new one. Once you have logged in to your Nintendo Account, you will be asked to link it to your Pokémon Trainer Club account. This will permit Nintendo to share certain account information with Pokémon (such as your date of birth) so that Pokémon can confirm that the same individual controls both the Pokémon Trainer Club account and the Nintendo Account. In order for account ownership to be validated, the date of birth registered to both accounts must match.')}</p>
                    <p>
                      <Trans
                        t={t}
                        i18nKey='nintendo_link_information_three'
                        defaults="While your accounts are linked, Pokémon will also receive from Nintendo the gameplay results of Online Video Game Competitions you participate in. You may request that your accounts be unlinked at any time via the customer service portal: <termsLink>http://support.pokemon.com</termsLink>. If your accounts are unlinked, you will no longer be eligible to receive Championship Points from Online Video Game Competitions in which a Nintendo Account linked to a Pokémon Trainer Club account is required. Championship Points that are awarded to a Pokémon Trainer Club account D’s Player ID will remain even if the Nintendo Account has been unlinked."
                        components={{ termsLink: <a target='_blank' rel="noopener noreferrer" href={supportLink} /> }}
                      />
                    </p>
                    <p>
                      <Trans
                        t={t}
                        i18nKey='nintendo_link_information_four'
                        defaults="Your use of Nintendo Switch Online and the sharing of information from your Nintendo Account to your Pokémon Trainer Club account is subject to <userAgreementLink>Nintendo’s User Agreement</userAgreementLink> and <privacyPolicyLink>Privacy Policy</privacyPolicyLink>, which are read and agreed to when setting up a Nintendo Account."
                        components={{ userAgreementLink: <a target='_blank' rel="noopener noreferrer" href={nintendoTermsLink} />, privacyPolicyLink: <a target='_blank' rel="noopener noreferrer" href={nintendoPrivacyLink} /> }}
                      />
                    </p>
                    <p>
                      <Trans
                        t={t}
                        i18nKey='pokemon_go_terms_niantic_privacy_notice'
                        defaults="For information on Pokémon’s use of your personal information, please see our <privacyNoticeLink>Privacy Notice.</privacyNoticeLink>"
                        components={{ privacyNoticeLink: <a target='_blank' rel="noopener noreferrer" href={privacyLink} /> }}
                      />
                    </p>
                    <Button
                      text={t('nintendo_link_button', 'GO TO NINTENDO ACCOUNT')}
                      buttonStatus='primary'
                      data-testid="nintendo-link-button-test"
                      type="submit"
                      disabled={ submitting }
                      style={{ width: '100%' }}
                    />
                  </div>
                </form>
              )
            }
          />
        </BaseContentBox>
      )}
    </>
  )
}
